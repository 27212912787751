import ReactDOM from "react-dom/client";
import { createGlobalStyle } from "styled-components";
import { Provider as JotaiProvider } from "jotai";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { App } from "./App";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'NanumSquareNeoBrg';
    src: url('/NanumSquareNeo-bRg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body, html {
    margin: 0;
    padding: 0;
    font-family: 'NanumSquareNeoBrg', sans-serif;
    line-height: 1.7;
    overflow-x: hidden;
  }

  table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #ffffff;
  color: #333;
  text-align: left;
}

td {
  background-color: #f9f9f9;
}

  textarea , input , button, div, p, span, h1,h2,h3,h4,h5,h6, select,option {
    font-family: 'NanumSquareNeoBrg', sans-serif !important;
    line-height: 1.7;
  }

`;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <JotaiProvider>
      <GlobalStyle />
      <App />
    </JotaiProvider>
  </QueryClientProvider>
);
