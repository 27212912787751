import { atom } from 'jotai';

export const openSidebarAtom = atom(true);

export const setOpenSidebarAtom = atom(
    (get) => get(openSidebarAtom),
    (get, set) => set(openSidebarAtom, true)
);

export const setCloseSidebarAtom = atom(
    (get) => get(openSidebarAtom),
    (get, set) => set(openSidebarAtom, false)
);
