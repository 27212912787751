import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { setOpenSidebarAtom } from "../Atom/sidebarAtom";
import {
  ChattingAtom,
  checkChattingLengthAtom,
  recommedBtnAtom,
} from "../Atom/ChattingAtom";
import { Chatting } from "./Chatting/Chatting";

import { FAQ } from "./Chatroom/FAQ";
import { ChatInput } from "./Chatroom/ChatInput/ChatInput";
import { RecommendQuestion } from "./Chatroom/RecommendQuestion/RecommendQuestion";
import {
  chatbotSocketAtom,
  currentChatbotAtom,
  flowAtom,
} from "../Atom/chatbotAtom";
import { useRef, useEffect } from "react";
import { whenChatbotChangeOrInitializeAtom } from "../ViewModel/ChattingViewModel";

export function Chatroom() {
  const [sidebar, setOpenSidebar] = useAtom(setOpenSidebarAtom);
  const [chatting] = useAtom(ChattingAtom);
  const [currentChatbot] = useAtom(currentChatbotAtom);
  const [checkChattingLength] = useAtom(checkChattingLengthAtom);
  const [chatbotSocket] = useAtom(chatbotSocketAtom);
  const [isClickRecommedBtn, handleRecommendBtn] = useAtom(recommedBtnAtom);
  const [flow] = useAtom(flowAtom);
  const [, whenChatbotChangeOrInitialize] = useAtom(
    whenChatbotChangeOrInitializeAtom
  );

  // ref to auto-scroll to the bottom of the chat messages
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [chatting]);

  useEffect(() => {
    whenChatbotChangeOrInitialize();
  }, [currentChatbot]);

  if (flow === null) return null;

  return (
    <Stack sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {/* Chat Content (Top Section with scroll) */}
      <Stack sx={{ flexGrow: 1, overflowY: "auto", padding: 2 }}>
        <IconButton
          onClick={setOpenSidebar}
          sx={{
            color: "black",
            width: "50px",
            height: "50px",
            visibility: sidebar ? "hidden" : "visible",
          }}
        >
          <KeyboardDoubleArrowRightIcon sx={{ fontSize: 30 }} />
        </IconButton>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack sx={{ width: sidebar ? "70%" : "90%", gap: "40px" }}>
            {chatting
              .filter((chat) => chat.name === currentChatbot)
              .map((chat, index) => (
                <Chatting
                  key={`chatting-${index}`}
                  name={chat.name}
                  image={chat.image}
                  text={chat.text}
                  date={chat.date}
                  chattingUser={chat.chattingUser}
                  type={chat.type}
                />
              ))}
            <div ref={chatEndRef} />{" "}
          </Stack>
        </Box>
      </Stack>

      <Stack
        sx={{
          width: "100%",
          background: "rgba(255, 255, 255,0)",
          position: "sticky",
          bottom: "0",
          paddingBottom: "20px",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(10px)",
          WebkitBackdropFilter: "blur(10px)",
        }}
      >
        {isClickRecommedBtn && (
          <Stack sx={{ width: "40%", marginBottom: "30px" }}>
            <FAQ />
          </Stack>
        )}

        <Stack
          sx={{
            width: sidebar ? "60%" : "50%",
            height: "100%",
            marginBottom: "10px",
            alignItems: "end",
            justifyContent: "center",
            gap: "5px",
          }}
          direction="row"
        >
          <ChatInput />
        </Stack>

        {!isClickRecommedBtn && (
          <Typography
            color={"#afafaf"}
            sx={{ cursor: "pointer" }}
            onClick={() => handleRecommendBtn(true)}
          >
            추천질문을 보고 싶어요!!
          </Typography>
        )}

        {isClickRecommedBtn && (
          <Stack
            sx={{
              width: sidebar ? "60%" : "50%",
            }}
            direction="row"
            gap={"50px"}
          >
            <RecommendQuestion />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
