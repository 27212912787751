import { Card, styled } from "@mui/material";

export const CustomCard = styled(Card)(({ theme }) => ({
  // flex: 1,
  background: "#efefef",
  border: "1px solid #afafaf",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  margin: "10px", // Added margin for spacing
  height: "180px",
  width: "25%",
}));

export const FirstCard = styled(Card)(({ theme }) => ({
  // flex: 1,
  background: "#00326C",
  border: "1px solid #afafaf",
  cursor: "pointer",
  display: "flex",
  color: "white",
  flexDirection: "column",
  justifyContent: "space-between",
  margin: "10px", // Added margin for spacing
  height: "180px",
  width: "25%",
}));
