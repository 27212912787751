import * as S from "../Components/Chatting/styled";

export function getHighlightedAndConvertedText(
  text: string,
  searchValue: string
) {
  if (!searchValue) return text;

  const regex = new RegExp(`(${searchValue})`, "gi");
  let highlightIndex = 0;

  return text.split(regex).map((part, index) => {
    if (part.toLowerCase() === searchValue.toLowerCase()) {
      const id = `highlight-search-field`;
      return (
        <span
          key={id}
          className={id}
          style={{ backgroundColor: "yellow", color: "black" }}
        >
          {part}
        </span>
      );
    }
    return part;
  });
}
