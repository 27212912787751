import { Avatar, Box, Stack, Typography } from "@mui/material";
import {
  ChattingProps,
  chatTypeAtom,
  searchValueAtom,
} from "../../Atom/ChattingAtom";
import { useAtom } from "jotai";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { flowAtom } from "../../Atom/chatbotAtom";
import React, { ReactNode } from "react";
import { getHighlightedAndConvertedText } from "../../Util/HighlightTextAndAutoLineBreak";

interface HighlightedTextProps {
  children: ReactNode;
}

export function Chatting({
  name,
  image,
  text,
  date,
  type,
  chattingUser,
}: ChattingProps) {
  const [chatType] = useAtom(chatTypeAtom);
  const [searchValue] = useAtom(searchValueAtom);

  const HighlightedText = ({ children }: HighlightedTextProps) => {
    const childArray = React.Children.toArray(children);

    return (
      <>
        {childArray.map((child, index) => {
          // child가 string일 경우에만 하이라이트 적용
          if (typeof child === "string") {
            return getHighlightedAndConvertedText(
              child,
              chatType === "ask" ? "" : searchValue
            );
          }
          // child가 string이 아니면 그대로 렌더링
          return child;
        })}
      </>
    );
  };

  // 챗봇 채팅
  if (chattingUser === "chatbot" && type == "text")
    return (
      <Stack direction={"row"} sx={{ gap: "15px", width: "100%" }}>
        <Avatar src={image} />
        <Stack sx={{ gap: "3px", maxWidth: "70%" }}>
          <Typography>{name}</Typography>
          <Box
            sx={{ background: "#ececec", borderRadius: "5px", padding: "15px" }}
          >
            {getHighlightedAndConvertedText(
              text,
              chatType === "ask" ? "" : searchValue
            )}
          </Box>
          <Typography fontSize={"13px"} color={"#8f8f8f"}>
            {date}
          </Typography>
        </Stack>
      </Stack>
    );

  if (chattingUser === "chatbot" && type === "markdown") {
    return (
      <Stack direction={"row"} sx={{ gap: "15px", width: "100%" }}>
        <Avatar src={image} />
        <Stack sx={{ gap: "3px", maxWidth: "70%" }}>
          <Typography>{name}</Typography>
          <Box
            sx={{ background: "#ececec", borderRadius: "5px", padding: "15px" }}
          >
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                p: ({ node, children }) => (
                  <p>
                    <HighlightedText>{children}</HighlightedText>
                  </p>
                ),
                h1: ({ node, children }) => (
                  <h1>
                    <HighlightedText>{children}</HighlightedText>
                  </h1>
                ),
                h2: ({ node, children }) => (
                  <h2>
                    <HighlightedText>{children}</HighlightedText>
                  </h2>
                ),
                h3: ({ node, children }) => (
                  <h3>
                    <HighlightedText>{children}</HighlightedText>
                  </h3>
                ),
                h4: ({ node, children }) => (
                  <h4>
                    <HighlightedText>{children}</HighlightedText>
                  </h4>
                ),
                h5: ({ node, children }) => (
                  <h5>
                    <HighlightedText>{children}</HighlightedText>
                  </h5>
                ),
                h6: ({ node, children }) => (
                  <h6>
                    <HighlightedText>{children}</HighlightedText>
                  </h6>
                ),
                ul: ({ node, children }) => (
                  <ul>
                    <HighlightedText>{children}</HighlightedText>
                  </ul>
                ),
                ol: ({ node, children }) => (
                  <ol>
                    <HighlightedText>{children}</HighlightedText>
                  </ol>
                ),
                li: ({ node, children }) => (
                  <li>
                    <HighlightedText>{children}</HighlightedText>
                  </li>
                ),
                table: ({ node, children }) => (
                  <table>
                    <HighlightedText>{children}</HighlightedText>
                  </table>
                ),
                thead: ({ node, children }) => (
                  <thead>
                    <HighlightedText>{children}</HighlightedText>
                  </thead>
                ),
                tbody: ({ node, children }) => (
                  <tbody>
                    <HighlightedText>{children}</HighlightedText>
                  </tbody>
                ),
                tr: ({ node, children }) => (
                  <tr>
                    <HighlightedText>{children}</HighlightedText>
                  </tr>
                ),
                th: ({ node, children }) => (
                  <th>
                    <HighlightedText>{children}</HighlightedText>
                  </th>
                ),
                td: ({ node, children }) => (
                  <td>
                    <HighlightedText>{children}</HighlightedText>
                  </td>
                ),
                blockquote: ({ node, children }) => (
                  <blockquote>
                    <HighlightedText>{children}</HighlightedText>
                  </blockquote>
                ),
                code: ({ node, children }) => (
                  <code>
                    <HighlightedText>{children}</HighlightedText>
                  </code>
                ),
                pre: ({ node, children }) => (
                  <pre>
                    <HighlightedText>{children}</HighlightedText>
                  </pre>
                ),
                em: ({ node, children }) => (
                  <em>
                    <HighlightedText>{children}</HighlightedText>
                  </em>
                ),
                strong: ({ node, children }) => (
                  <strong>
                    <HighlightedText>{children}</HighlightedText>
                  </strong>
                ),
                a: ({ node, children }) => (
                  <a>
                    <HighlightedText>{children}</HighlightedText>
                  </a>
                ),
                img: ({ node, src, alt }) => <img src={src} alt={alt} />,
              }}
            >
              {text}
            </ReactMarkdown>
          </Box>
          <Typography fontSize={"13px"} color={"#8f8f8f"}>
            {date}
          </Typography>
        </Stack>
      </Stack>
    );
  }

  // user 채팅
  return (
    <Stack
      direction={"row"}
      sx={{ gap: "15px", width: "100%", justifyContent: "end" }}
    >
      <Stack sx={{ gap: "3px", textAlign: "end", maxWidth: "60%" }}>
        {/* <Typography>{name}</Typography> */}
        <Box
          sx={{
            background: "#00326C",
            borderRadius: "5px",
            padding: "15px",
            color: "white",
          }}
        >
          {getHighlightedAndConvertedText(
            text,
            chatType === "ask" ? "" : searchValue
          )}
        </Box>
        <Typography fontSize={"13px"} color={"#8f8f8f"}>
          {date}
        </Typography>
      </Stack>
      {/* <Avatar src={image} /> */}
    </Stack>
  );
}
