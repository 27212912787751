import { useAtom } from "jotai";
import { IconButton, CardContent, CardActions } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  disableNextButtonAtom,
  disablePrevButtonAtom,
  hadleRecommendQuestionSendMessage,
  recommendQuestionListAtom,
  recommendQuestionPageNumAtom,
} from "../../../Atom/ChattingAtom";
import { ArrowForward } from "@mui/icons-material";
import * as S from "./styled";
import { currentChatbotAtom } from "../../../Atom/chatbotAtom";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

export function RecommendQuestion() {
  const [recommendQeustionList] = useAtom(recommendQuestionListAtom);
  const [recommendQuestionPageNum] = useAtom(recommendQuestionPageNumAtom);
  const [currentChatbot] = useAtom(currentChatbotAtom);
  const [disablePrevButton, setMinusPageNum] = useAtom(disablePrevButtonAtom);
  const [disableNextButton, setPlusPageNum] = useAtom(disableNextButtonAtom);
  const [, onRecommendQuestionSend] = useAtom(
    hadleRecommendQuestionSendMessage
  );

  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      <IconButton
        aria-label="back"
        size="large"
        style={{
          width: "48px",
          height: "48px",
          borderRadius: "50%",
          color: disablePrevButton ? "#8f8f8f" : "#336699",
        }}
        onClick={setMinusPageNum}
      >
        <ArrowBackIosNewIcon style={{ fontSize: "24px" }} />
      </IconButton>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          width: "calc(100% - 96px)",
        }}
      >
        <S.FirstCard key={`recommend-question-0`}>
          <CardContent>이런질문은 어때요</CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <IconButton aria-label="add to favorites" sx={{ color: "white" }}>
              <QuestionMarkIcon />
            </IconButton>
          </CardActions>
        </S.FirstCard>
        {recommendQeustionList[currentChatbot].map(
          (recommendQuestion, index) =>
            index < (recommendQuestionPageNum + 1) * 4 &&
            index >= recommendQuestionPageNum * 4 && (
              <S.CustomCard
                onClick={() => onRecommendQuestionSend(recommendQuestion)}
                key={`recommend-question-${index}`}
              >
                <CardContent>{recommendQuestion}</CardContent>
                <CardActions sx={{ justifyContent: "end" }}>
                  <IconButton aria-label="add to favorites">
                    <ArrowForward />
                  </IconButton>
                </CardActions>
              </S.CustomCard>
            )
        )}
      </div>

      <IconButton
        aria-label="forward"
        size="large"
        style={{
          width: "48px",
          height: "48px",
          borderRadius: "50%",
          color: disableNextButton ? "#8f8f8f" : "#336699",
        }}
        onClick={setPlusPageNum}
      >
        <ArrowForwardIosIcon style={{ fontSize: "24px" }} />
      </IconButton>
    </div>
  );
}
