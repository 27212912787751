import { atomWithStorage } from "jotai/utils";
import CryptoJS from "crypto-js";

const encrypt = (data: any) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(data),
    "40nSCD1DaRXylZWnd4CDtftKC8DlyweaOfa1"
  ).toString();
};

const decrypt = (data: string) => {
  try {
    const bytes = CryptoJS.AES.decrypt(
      data,
      "40nSCD1DaRXylZWnd4CDtftKC8DlyweaOfa1"
    );
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  } catch (error) {
    return null;
  }
};

// 커스텀 atomWithStorage
export const encryptedAtomWithStorage = <T>(key: string, initialValue: T) => {
  const storage = {
    getItem: (key: string) => {
      const storedValue = sessionStorage.getItem(key);
      return storedValue ? decrypt(storedValue) : initialValue;
    },
    setItem: (key: string, newValue: T) => {
      const encryptedValue = encrypt(newValue);
      sessionStorage.setItem(key, encryptedValue);
    },
    removeItem: (key: string) => {
      sessionStorage.removeItem(key);
    },
  };
  return atomWithStorage<T>(key, initialValue, storage);
};
