import { Box, IconButton, InputBase, Menu } from '@mui/material';
import styled from 'styled-components';

export const SelectWrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    cursor: pointer;

    .MuiIconButton-root {
        width: 40px;
        height: 40px;
        padding: 10px;
        background-color: #e5e8eb;
        border-radius: 50%;
        display:flex;
        align-items: center;
        justify-content: center;
        &:hover {
        background-color: #e0e0e0;
        }
    }

    .DropdownIcon {
        margin-left: 5px;
    }
`;

export const CustomMenu = styled(Menu)`
    & .MuiPaper-root {
    elevation: 0;
    overflow: visible;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
    margin-top: 0.8em;

    & .MuiAvatar-root {
      width: 32px;
      height: 32px;
      margin-left: -0.5em;
      margin-right: 1em;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 30px;
      width: 10px;
      height: 10px;
      background-color: white;
      transform: translateY(-50%) rotate(45deg);
      z-index: 0;
    }
  }
`;

export const InputContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#e5e8eb',
    borderRadius: '30px',
    padding: '10px 15px',
    width: '100%',
    minHeight: "40px",
}));

export const CustomInputBase = styled(InputBase)(({ theme }) => ({
    flex: 1,
    marginLeft: "15px",
}));

export const CustomIconButton = styled(IconButton)`
    align-self: end;
`;