import { Box, Button, Typography } from "@mui/material";
import { Sidebar } from "./Components/Sidebar";
import { Chatroom } from "./Components/Chatroom";
import { useAtom } from "jotai";
import { openSidebarAtom } from "./Atom/sidebarAtom";
import { userAtom } from "./Atom/publicAtom";
import { useEffect } from "react";
import { judgeUserRightAtom } from "./ViewModel/RootViewModel";

export function App() {
  const [openSidebar] = useAtom(openSidebarAtom);
  const [user] = useAtom(userAtom);
  const [, judgeUserRight] = useAtom(judgeUserRightAtom);

  useEffect(() => {
    judgeUserRight();
  }, []);

  if (user === null)
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography>로그인 후에 이용해주세요.</Typography>
        <Button
          onClick={() =>
            (window.location.href = "https://www.ipsispace.com/consulting/ai/")
          }
        >
          돌아가기
        </Button>
      </Box>
    );

  return (
    <Box
      key="app-fullcontainer"
      sx={{ width: "100vw", height: "100vh", display: "flex" }}
    >
      {openSidebar && (
        <Box
          key="sidebar-fullcontainer"
          sx={{
            background: "#00326C",
            transition: "flex 0.5s", // Apply transition for width change
            position: "fixed",
            height: "100vh",
            left: 0,
            top: 0,
            width: "16vw",
          }}
        >
          <Sidebar />
        </Box>
      )}
      <Box
        key="chatroom-fullcontainer"
        sx={{
          marginLeft: openSidebar ? "16vw" : 0,
          width: openSidebar ? "84vw" : "100vw",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Chatroom />
      </Box>
    </Box>
  );
}
