import { useQuery } from "@tanstack/react-query";
import { DokgabiFlowProps } from "./DokgabiFlow";
import { ChattingAtom, chattingValueAtom } from "../Atom/ChattingAtom";
import dayjs from "dayjs";
import { flowAtom } from "../Atom/chatbotAtom";
import { atom } from "jotai";

export async function getFlow(id: string): Promise<DokgabiFlowProps | null> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  const response = await fetch(
    `https://dashboard.dokgabi.ai/api/getSocketFLow`,
    requestOptions
  );
  if (!response.ok) {
    return null;
  }
  const result = await response.json();

  return result.results;
}

export async function requestChatbot(
  input_message: string,
  flow_socket_name: string,
  user_id: string,
  handleStreamMessage: (
    message: string,
    isFirst: boolean,
    isNotStream: boolean
  ) => void
): Promise<void> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      flow: flow_socket_name,
      message: input_message,
      user_id: user_id,
    }),
  };

  try {
    const response = await fetch(
      `https://dashboard.dokgabi.ai/api/makeChatbotResponse`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error("응답을 불러오지 못했습니다.");
    }

    // 스트리밍 응답인지 아닌지 확인
    if (response.body) {
      // 스트리밍 응답 처리
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let isFirst = true;
      let message = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          isFirst = true;
          break;
        }

        const chunk = decoder.decode(value, { stream: true });
        message += chunk;
        handleStreamMessage(message, isFirst, false); // 스트리밍이므로 isNotStream = false
        isFirst = false;
      }
    } else {
      // 일반 응답 처리 (스트리밍이 아닌 경우)
      const jsonResponse = await response.json();
      const message = JSON.stringify(jsonResponse); // JSON 구조를 문자열로 변환
      handleStreamMessage(message, true, true); // 스트리밍이 아니므로 isNotStream = true
    }
  } catch (error) {
    console.error("응답 처리 중 오류 발생:", error);
  }
}
