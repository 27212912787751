import { atom } from "jotai";
import { recommendQuestionPageNumAtom } from "./ChattingAtom";
import { DokgabiFlowProps } from "../Queries/DokgabiFlow";

export const chatbotListAtom = atom<string[]>([
  "자료 추천 도우미",
  "주제 탐구 보고서 도우미",
  "선배들의 학생부 추천",
  "도서연계 활동 도우미",
]);

export const chatbotSocketAtom = atom<string[]>([
  "8hrwxCg4BeBh8GEkeyhPuwRgNQ9P1hcHaumH",
  "5WTLBjCMFS6Bg4cqCvfGdMaru7459q3EvJKc",
  "jfsbwvmktD9buhlF84YxrgTPlvDFPI5rW8G6",
  "epTwY0RyJkf2b6MwxCVduyqAdjiDpmwMZBFP",
]);

export const flowAtom = atom<DokgabiFlowProps | null>(null);

export const currentChatbotAtom = atom("자료 추천 도우미");

export const whenChatbotClick = atom(
  (get) => get(currentChatbotAtom),
  (get, set, text: string) => {
    if (text !== get(currentChatbotAtom)) {
      set(currentChatbotAtom, text);
      set(recommendQuestionPageNumAtom, 0);
    }
  }
);
