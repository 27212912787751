import { atom } from "jotai";
import {
  chatbotListAtom,
  chatbotSocketAtom,
  currentChatbotAtom,
  flowAtom,
} from "../Atom/chatbotAtom";
import { getFlow, requestChatbot } from "../Queries/DokgabiFlowQueries";
import {
  ChattingAtom,
  chattingValueAtom,
  chatTypeAtom,
} from "../Atom/ChattingAtom";
import dayjs from "dayjs";
import { userAtom } from "../Atom/publicAtom";

export const whenChatbotChangeOrInitializeAtom = atom(
  null,
  async (get, set) => {
    const currentChatbot = get(currentChatbotAtom);
    const chatbotList = get(chatbotListAtom);
    const chatting = get(ChattingAtom);
    const user = get(userAtom);

    set(chatTypeAtom, "ask");

    const currentChatbotIndex = chatbotList.findIndex(
      (chatbot, index) => chatbot === currentChatbot
    );
    const socketList = get(chatbotSocketAtom);

    const result = await getFlow(socketList[currentChatbotIndex]);

    if (result) {
      if (
        chatting.filter((chat, index) => chat.name === result.name).length < 1
      ) {
        const handleStreamMessage = (message: any, isFirst: boolean) => {
          if (isFirst) {
            set(ChattingAtom, (current) => [
              ...current,
              {
                name: result.name,
                image: `https://dashboard.dokgabi.ai${result.character_image.file_path}`,
                text: message,
                date: dayjs().format("MM.DD HH:mm"),
                chattingUser: "chatbot",
                type: "markdown",
              },
            ]);
          } else {
            set(ChattingAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem) {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text: message,
                  },
                ];
              }

              return current;
            });
          }
        };

        await requestChatbot(
          "",
          result.chatbot_socket_parameter,
          user ?? "",
          handleStreamMessage
        );
      }

      set(flowAtom, result);
    }
  }
);

export const handleInputChange = atom(
  null,
  (
    get,
    set,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    set(chattingValueAtom, event.target.value);
  }
);
