import { Typography } from "@mui/material";
import { useAtom } from "jotai";
import {
  FAQAtom,
  hadleRecommendQuestionSendMessage,
} from "../../Atom/ChattingAtom";
import { currentChatbotAtom } from "../../Atom/chatbotAtom";

export function FAQ() {
  const [FAQ] = useAtom(FAQAtom);
  const [currentChatbot] = useAtom(currentChatbotAtom);
  const [, hadleFaQSendMessage] = useAtom(hadleRecommendQuestionSendMessage);

  return (
    <>
      <Typography key={"faq-title"} color={"#afafaf"} marginBottom={"10px"}>
        친구들이 자주 질문해요!
      </Typography>
      {FAQ[currentChatbot].map((ques, index) => (
        <Typography
          key={`faq-${index}`}
          color={"#afafaf"}
          marginBottom={"5px"}
          sx={{ cursor: "pointer" }}
          onClick={() => hadleFaQSendMessage(ques)}
        >
          {ques}
        </Typography>
      ))}
    </>
  );
}
