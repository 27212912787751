import { IconButton, Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { setCloseSidebarAtom } from "../Atom/sidebarAtom";
import { chatbotListAtom, whenChatbotClick } from "../Atom/chatbotAtom";

export function Sidebar() {
  const [sidebar, setCloseSidebar] = useAtom(setCloseSidebarAtom);
  const [chatbotList] = useAtom(chatbotListAtom);

  return (
    <Stack>
      <Stack
        direction="row"
        padding={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography
          sx={{
            color: "white",
            fontWeight: "bold",
            fontSize: "1.5rem",
            width: "100%",
          }}
        >
          입시스페이스 AI 챗봇 컨설턴트
        </Typography>
        {sidebar && (
          <IconButton onClick={setCloseSidebar} sx={{ color: "white" }}>
            <KeyboardDoubleArrowLeftIcon sx={{ fontSize: 30 }} />
          </IconButton>
        )}
      </Stack>
      <div style={{ height: "30px" }}></div>
      {chatbotList.map((chatbot, index) => (
        <ChatIconButton key={`chatlist-iconbutton-${index}`} text={chatbot} />
      ))}
    </Stack>
  );
}

interface ChatIconButtonProps {
  text: string;
}

export function ChatIconButton({ text }: ChatIconButtonProps) {
  const [currentChatbot, setCurrentChatbot] = useAtom(whenChatbotClick);

  return (
    <Stack
      sx={{
        height: "50px",
        background: currentChatbot === text ? "white" : "transparent",
        color: currentChatbot === text ? "black" : "white",
        justifyContent: "center",
        padding: "5px 15px",
        cursor: "pointer",
      }}
      onClick={() => setCurrentChatbot(text)}
    >
      <Typography>{text}</Typography>
    </Stack>
  );
}
