import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ArrowForward } from "@mui/icons-material";
import * as S from "./styled";
import { useAtom } from "jotai";
import {
  canTInputMessageAtom,
  chattingValueAtom,
  chatTypeAtom,
  handleClickAtom,
  handleCloseAndChangeTypeAtom,
  handleCloseAtom,
  handleInputChange,
  handleKeyDown,
  handleSendMessage,
  isChattingAskAtom,
  searchValueAtom,
  whenDownButtonClickAtom,
  whenUpButtonClickAtom,
} from "../../../Atom/ChattingAtom";
import PendingIcon from "@mui/icons-material/Pending";

export function ChatInput() {
  const [isChattingAsk] = useAtom(isChattingAskAtom);
  const [chatType] = useAtom(chatTypeAtom);
  const [, handleClick] = useAtom(handleClickAtom);
  const [, handleClose] = useAtom(handleCloseAtom);
  const [, handleCloseAndChange] = useAtom(handleCloseAndChangeTypeAtom);
  const [inputValue] = useAtom(chattingValueAtom);
  const [, onInputChange] = useAtom(handleInputChange);
  const [, onSendMessage] = useAtom(handleSendMessage);
  const [, onKeyDown] = useAtom(handleKeyDown);
  const [searchValue] = useAtom(searchValueAtom);
  const [canTInputMessage] = useAtom(canTInputMessageAtom);
  const [, whenDownButtonClick] = useAtom(whenDownButtonClickAtom);
  const [, whenUpButtonClick] = useAtom(whenUpButtonClickAtom);

  return (
    <>
      <S.SelectWrapper onClick={handleClick}>
        <div className="MuiIconButton-root">
          {chatType === "ask" ? <ChatBubbleOutlineIcon /> : <SearchIcon />}
        </div>
        {isChattingAsk ? (
          <KeyboardArrowUpIcon className="DropdownIcon" />
        ) : (
          <KeyboardArrowDownIcon className="DropdownIcon" />
        )}
      </S.SelectWrapper>
      <S.CustomMenu
        anchorEl={isChattingAsk}
        id="account-menu"
        open={Boolean(isChattingAsk)}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <MenuItem onClick={() => handleCloseAndChange("ask")}>
          <ListItemIcon>
            <ChatBubbleOutlineIcon />
          </ListItemIcon>
          <Typography>질문 검색</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleCloseAndChange("search")}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <Typography>대화 내용 검색</Typography>
        </MenuItem>
      </S.CustomMenu>

      <S.InputContainer>
        <S.CustomInputBase
          placeholder={
            chatType === "ask"
              ? canTInputMessage
                ? "챗봇이 대화를 생성 중입니다."
                : "질문을 입력해보세요."
              : "찾을 대화 내용을 검색해보세요."
          }
          multiline
          maxRows={10}
          value={chatType === "ask" ? inputValue : searchValue}
          onChange={canTInputMessage ? () => null : onInputChange}
          onKeyDown={canTInputMessage ? () => null : onKeyDown}
        />
        <S.CustomIconButton
          onClick={
            chatType === "ask"
              ? canTInputMessage
                ? () => null
                : onSendMessage
              : () => null
          }
        >
          {chatType === "ask" ? (
            canTInputMessage ? (
              <PendingIcon />
            ) : (
              <ArrowForward />
            )
          ) : null}
        </S.CustomIconButton>

        {chatType === "search" && (
          <>
            <S.CustomIconButton onClick={whenDownButtonClick}>
              <KeyboardArrowDownIcon />
            </S.CustomIconButton>
            <S.CustomIconButton onClick={whenUpButtonClick}>
              <KeyboardArrowUpIcon />
            </S.CustomIconButton>
          </>
        )}
      </S.InputContainer>
    </>
  );
}
