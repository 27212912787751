import dayjs from "dayjs";
import { atom } from "jotai";
import { currentChatbotAtom, flowAtom } from "./chatbotAtom";
import { requestChatbot } from "../Queries/DokgabiFlowQueries";
import { userAtom } from "./publicAtom";
import { encryptedAtomWithStorage } from "./EncryptedAtomWithStorage";

export interface ChattingProps {
  name: string;
  image: string;
  text: string;
  type: string;
  date: string;
  chattingUser: string;
}

export const ChattingAtom = encryptedAtomWithStorage<ChattingProps[]>(
  "chattingAtom",
  []
);

export const recommedBtnAtom = atom(false);

export const canTInputMessageAtom = atom(false);

// FAQ.tsx
export const FAQAtom = atom<Record<string, string[]>>({
  "자료 추천 도우미": [
    "1. 의학 관련 자료를 추천해줘.",
    "2. 인공지능에 관한 최신 연구 자료를 알려줘.",
  ],
  "주제 탐구 보고서 도우미": [
    "1. 환경 문제에 대한 주제 보고서를 작성하려면 어디서 시작해야 할까?",
    "2. 역사적 사건을 주제로 보고서를 작성하는 방법을 알려줘.",
  ],
  "선배들의 학생부 추천": [
    "1. 선배들이 작성한 학생부 예시를 볼 수 있을까?",
    "2. 학생부 항목별로 어떤 활동을 기록하면 좋을까?",
  ],
  "도서연계 활동 도우미": [
    "1. 독서와 관련된 추천 도서를 알려줘.",
    "2. 문학 작품과 연계할 수 있는 도서 추천 부탁해.",
  ],
});

// ChatInput.tsx
export const isChattingAskAtom = atom<HTMLElement | null>(null);
export const chatTypeAtom = atom("ask");
export const chattingValueAtom = atom("");
export const searchValueAtom = atom("");

export const currentSearchIndexAtom = atom(0);

export const handleClickAtom = atom(
  null,
  (_, set, event: React.MouseEvent<HTMLButtonElement>) => {
    set(isChattingAskAtom, event.currentTarget);
  }
);

export const handleCloseAtom = atom(null, (_, set) => {
  set(isChattingAskAtom, null);
});

export const handleCloseAndChangeTypeAtom = atom(
  null,
  (get, set, type: string) => {
    const chatType = get(chatTypeAtom);
    if (chatType !== type) {
      set(isChattingAskAtom, null);
      set(chatTypeAtom, type);
      set(chattingValueAtom, "");
      set(recommedBtnAtom, false);
    }
  }
);

export const handleInputChange = atom(
  null,
  (
    get,
    set,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const chatType = get(chatTypeAtom);

    if (chatType === "ask") set(chattingValueAtom, event.target.value);
    else {
      set(searchValueAtom, event.target.value);

      setTimeout(() => {
        const firstHighlight = document.getElementsByClassName(
          "highlight-search-field"
        )[0];
        if (firstHighlight) {
          firstHighlight.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 100);
    }
  }
);

export const hadleRecommendQuestionSendMessage = atom(
  null,
  async (get, set, recommend: string) => {
    set(recommedBtnAtom, false);
    const flow = get(flowAtom);
    set(canTInputMessageAtom, true);

    if (flow) {
      const newMessage = {
        name: flow.name,
        image: `https://dashboard.dokgabi.ai${flow.character_image.file_path}`,
        text: recommend,
        date: dayjs().format("MM.DD HH:mm"),
        chattingUser: "user",
        type: "markdown",
      };

      set(ChattingAtom, (prevChatting) => [...prevChatting, newMessage]);
      set(chattingValueAtom, "");

      // 여기에서 backend랑 통신을 하도록 하자.
      const handleStreamMessage = (message: string, isFirst: boolean) => {
        if (isFirst)
          set(ChattingAtom, (current) => [
            ...current,
            {
              name: flow.name,
              image: `https://dashboard.dokgabi.ai/media${flow.character_image.file_path}`,
              text: message,
              date: dayjs().format("MM.DD HH:mm"),
              chattingUser: "chatbot",
              type: "markdown",
            },
          ]);
        else
          set(ChattingAtom, (current) => {
            const lastItem = current[current.length - 1];

            if (lastItem) {
              return [
                ...current.slice(0, -1),
                {
                  ...lastItem,
                  text: message,
                },
              ];
            }

            return current;
          });
      };

      await requestChatbot(
        recommend,
        get(flowAtom)!.chatbot_socket_parameter,
        get(userAtom) ?? "",
        handleStreamMessage
      );
    }

    set(canTInputMessageAtom, false);
  }
);

export const handleSendMessage = atom(null, async (get, set) => {
  const inputValue = get(chattingValueAtom).trim();
  if (inputValue === "") return;

  const flow = get(flowAtom);
  set(canTInputMessageAtom, true);

  if (flow) {
    const newMessage = {
      name: flow.name,
      image: `https://dashboard.dokgabi.ai${flow.character_image.file_path}`,
      text: inputValue,
      date: dayjs().format("MM.DD HH:mm"),
      chattingUser: "user",
      type: "markdown",
    };

    set(ChattingAtom, (prevChatting) => [...prevChatting, newMessage]);
    set(chattingValueAtom, "");
    set(recommedBtnAtom, false);

    // 여기에서 backend랑 통신을 하도록 하자.
    const handleStreamMessage = (message: string, isFirst: boolean) => {
      if (isFirst)
        set(ChattingAtom, (current) => [
          ...current,
          {
            name: flow.name,
            image: `https://dashboard.dokgabi.ai/media${flow.character_image.file_path}`,
            text: message,
            date: dayjs().format("MM.DD HH:mm"),
            chattingUser: "chatbot",
            type: "markdown",
          },
        ]);
      else
        set(ChattingAtom, (current) => {
          const lastItem = current[current.length - 1];

          if (lastItem) {
            return [
              ...current.slice(0, -1),
              {
                ...lastItem,
                text: message,
              },
            ];
          }

          return current;
        });
    };

    await requestChatbot(
      inputValue,
      get(flowAtom)!.chatbot_socket_parameter,
      get(userAtom) ?? "",
      handleStreamMessage
    );
  }

  set(canTInputMessageAtom, false);
});

export const handleKeyDown = atom(
  null,
  async (
    get,
    set,
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      const inputValue = get(chattingValueAtom).trim();
      if (inputValue === "") return;

      const flow = get(flowAtom);
      set(canTInputMessageAtom, true);

      if (flow) {
        const newMessage = {
          name: flow.name,
          image: `https://dashboard.dokgabi.ai${flow.character_image.file_path}`,
          text: inputValue,
          date: dayjs().format("MM.DD HH:mm"),
          chattingUser: "user",
          type: "text",
        };

        set(ChattingAtom, (prevChatting) => [...prevChatting, newMessage]);
        set(chattingValueAtom, "");
        set(recommedBtnAtom, false);

        // 여기에서 backend랑 통신을 하도록 하자.
        const handleStreamMessage = (message: string, isFirst: boolean) => {
          if (isFirst)
            set(ChattingAtom, (current) => [
              ...current,
              {
                name: flow.name,
                image: `https://dashboard.dokgabi.ai${flow.character_image.file_path}`,
                text: message,
                date: dayjs().format("MM.DD HH:mm"),
                chattingUser: "chatbot",
                type: "markdown",
              },
            ]);
          else
            set(ChattingAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem) {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text: message,
                  },
                ];
              }

              return current;
            });
        };

        await requestChatbot(
          inputValue,
          get(flowAtom)!.chatbot_socket_parameter,
          get(userAtom) ?? "",
          handleStreamMessage
        );
      }

      set(canTInputMessageAtom, false);
    }
  }
);

export const checkChattingLengthAtom = atom((get) => {
  const chatting = get(ChattingAtom);

  if (
    chatting.filter((chat) => chat.name === get(currentChatbotAtom)).length >= 2
  )
    return true;

  return false;
});

//RecommendQeustion.tsx
export const recommendQuestionListAtom = atom<Record<string, string[]>>({
  "자료 추천 도우미": [
    "의학 분야의 도서를 추천해줘.",
    "최근 출판된 인공지능 관련 자료를 알려줘.",
    "환경 보호에 관한 논문을 추천해줘.",
    "사회학 분야의 최신 연구 자료를 추천해줘.",
    "자연과학 분야에서 중요한 논문은 뭐가 있을까?",
  ],
  "주제 탐구 보고서 도우미": [
    "역사적 사건을 주제로 한 보고서 자료를 추천해줘.",
    "기후 변화에 대한 탐구 보고서를 작성하려면?",
    "전쟁과 평화에 관한 주제 보고서를 어떻게 작성할까?",
    "현대 미술을 주제로 한 보고서 자료를 알려줘.",
    "과학적 발견에 대한 주제 보고서를 작성하는 방법은?",
  ],
  "선배들의 학생부 추천": [
    "선배들이 작성한 학생부 예시를 보여줘.",
    "학생부에 어떤 활동을 기록하면 좋을까?",
    "봉사활동 관련 학생부 기재 방법을 알려줘.",
    "학업 성취도를 높이는 방법에 대한 추천 자료가 있을까?",
    "학생부에서 돋보이는 활동은 무엇일까?",
  ],
  "도서연계 활동 도우미": [
    "의학 분야에서 추천할 만한 도서는?",
    "문학 작품과 연계할 수 있는 도서를 추천해줘.",
    "사회적 이슈를 다룬 도서를 알려줘.",
    "자기계발과 관련된 추천 도서가 있을까?",
    "심리학에 대한 도서 추천 부탁해.",
    "철학에 관한 책 중에서 읽을 만한 도서를 추천해줘.",
  ],
});

export const recommendQuestionPageNumAtom = atom(0);

export const disablePrevButtonAtom = atom(
  (get) => {
    const curPageNum = get(recommendQuestionPageNumAtom);

    if (curPageNum * 4 > 0) return false;

    return true;
  },
  (get, set) => {
    const curPageNum = get(recommendQuestionPageNumAtom);

    if (curPageNum * 4 > 0)
      set(recommendQuestionPageNumAtom, (current) => current - 1);
  }
);

export const disableNextButtonAtom = atom(
  (get) => {
    const curListLength = get(recommendQuestionListAtom)[
      get(currentChatbotAtom)
    ].length;
    const curPageNum = get(recommendQuestionPageNumAtom);

    if ((curPageNum + 1) * 4 <= curListLength - 1) return false;

    return true;
  },
  (get, set) => {
    const curListLength = get(recommendQuestionListAtom)[
      get(currentChatbotAtom)
    ].length;
    const curPageNum = get(recommendQuestionPageNumAtom);

    if ((curPageNum + 1) * 4 <= curListLength - 1)
      set(recommendQuestionPageNumAtom, (current) => current + 1);
  }
);

export const whenDownButtonClickAtom = atom(null, (get, set) => {
  const currentIdx = get(currentSearchIndexAtom);

  setTimeout(() => {
    const firstHighlight = document.getElementsByClassName(
      "highlight-search-field"
    )[currentIdx + 1];
    if (firstHighlight) {
      firstHighlight.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    set(currentSearchIndexAtom, (current) => current + 1);
  }, 100);
});

export const whenUpButtonClickAtom = atom(null, (get, set) => {
  const currentIdx = get(currentSearchIndexAtom);

  setTimeout(() => {
    const firstHighlight = document.getElementsByClassName(
      "highlight-search-field"
    )[currentIdx - 1];
    if (firstHighlight) {
      firstHighlight.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    set(currentSearchIndexAtom, (current) =>
      current != 0 ? current - 1 : current
    );
  }, 100);
});
