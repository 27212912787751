import { atom } from "jotai";
import { userAtom } from "../Atom/publicAtom";

export const judgeUserRightAtom = atom(null, (get, set) => {
  window.addEventListener("message", async (event) => {
    const allowedOrigins = ["https://ipsispace.com"];

    if (!allowedOrigins.includes(event.origin)) {
      return;
    }
    const data = event.data;

    if (data.message === "chattingWithChatbot") {
      set(userAtom, data.user);
    }
  });
});
